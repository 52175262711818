import { RightCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import "./index.scss";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ButtonChangeStatusItem from "./components/ButtonChangeStatusItem";
import { ThemeContext } from "handlers/ThemeContext";
import trashIcon from "./components/trash.png";
import { getLastFourChars } from "utils/Validation";

export const TYPE_QUICK_ORDER = "Counter";

export const getTypeTextOrder = (order) => {
  if (
    order?.order_source === "DINING" ||
    (order?.is_counter === "0" && !order?.order_source)
  )
    return {
      text: "Dine in",
      color: "#fff",
      backgroundColor: "#0AA699",
    };
  else if (
    order?.order_source === "PICKUP" ||
    (order?.is_counter === "1" && !order?.order_source)
  )
    return {
      text: "Pick up",
      color: "#fff",
      backgroundColor: "#1877F2",
    };
  else if (
    (order?.is_counter === null && order?.order_source === null) ||
    order?.order_source === "DELIVERY"
  )
    return {
      text: "Delivery",
      color: "#fff",
      backgroundColor: "#FFA600",
    };
  else
    return {
      text: "Unknow",
      color: "#fff",
      backgroundColor: "#FFA600",
    };
};

export const getTextNameTable = (order) => {
  if (order?.table_name && order?.table_name === TYPE_QUICK_ORDER)
    return `QO - ${order?.cart_name}`;
  if (order?.table_name && order?.table_name !== TYPE_QUICK_ORDER)
    return `${order?.table_name} - ${order?.cart_name}`;
  if (!order?.table_name && order?.order_number)
    return `${getLastFourChars(order?.order_number)}`;
  return "Unknow";
};

function OrderV2(props) {
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [isNotResponse, setNotResponse] = useState(false);

  const {
    order,
    openModal,
    handleSubmitBom,
    id,
    playSound = () => {},
    saveOrderListNotResponse = () => {},
    orderListNotResponse = [],
    playOrderNOtResponseAgain = () => {},
    turnOffAppSound,
    updateOrderNextStatus,
    itemStatus,
    onServed,
    onRemoveCancel,
  } = props;
  const findOrderOffSound = orderListNotResponse.find(
    (obj) => obj?.id == order?.id
  );
  const isOffSound =
    findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;
  const timePause =
    findOrderOffSound && findOrderOffSound?.timePause
      ? findOrderOffSound?.timePause
      : null;

  const showDetail = useRef(true);

  const handleClickOrder = () => {
    // callApiGetTurnOffAppSound();
    if (showDetail.current) {
      openModal(itemStatus, order);
    } else {
      showDetail.current = true;
    }
  };

  useEffect(() => {
    let countDownInterVal = setInterval(
      () => setCountdownDate(new Date()),
      1000
    );
    return () => clearInterval(countDownInterVal);
  }, []);

  const timePauseOrder = timePause
    ? timePause + parseInt(turnOffAppSound) * 60000
    : 0;

  let distanceToTimePause =
    timePauseOrder - Date.parse(new Date(countdownDate));

  useEffect(() => {
    if (distanceToTimePause < 0 && isOffSound) {
      playOrderNOtResponseAgain({ order });
    }
  }, [distanceToTimePause]);

  const { t } = useTranslation();
  const { isLightMode } = useContext(ThemeContext);
  return (
    <Draggable
      draggableId={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      key={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      index={id}
    >
      {(provided, snapshot) => (
        <span
          key={id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`order-item
            ${isNotResponse && !isOffSound ? "notResponse" : ""} 
            ${isLightMode ? "order-item-light" : ""}
            `}
            onClick={() => {
              handleClickOrder();
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgba(237, 237, 237, 1)",
                padding: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <h3
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 600,
                    marginBottom: 20,
                  }}
                >
                  {getTextNameTable(order)}
                </h3>
                <div
                  style={{
                    fontSize: 16,
                    color: "#5B5B5B",
                  }}
                  className={` ${isLightMode ? "text-black" : "text-white"}`}
                >
                  {moment(order?.created_at).format("HH:mm A")}
                </div>
              </div>
              <div
                style={{
                  width: "fit-content",
                  fontSize: 20,
                  fontWeight: 600,
                  color: getTypeTextOrder(order).color,
                  backgroundColor: getTypeTextOrder(order).backgroundColor,
                  padding: "10px 16px",
                  borderRadius: "36px",
                  marginLeft: "auto",
                  minWidth: 112,
                  textAlign: "center",
                }}
              >
                {getTypeTextOrder(order).text}
              </div>
            </div>
            {order?.assign_from?.name && order?.status === "pending" && (
              <div className="order-item-moved">
                <RightCircleOutlined />
                {`${t("movedFrom")} ${order?.assign_from?.name}`}
              </div>
            )}
            <div
              className={`order-item-box ${
                isLightMode ? "order-item-box-light" : ""
              }`}
            >
              {order?.items.map((item, index) => {
                return (
                  <div
                    className={`${
                      isLightMode ? "order-item-info-light" : "order-item-info"
                    }`}
                    key={`${item.id}-${index}`}
                  >
                    <div className="flex-sb-c">
                      <div>
                        <span
                          className={` ${
                            isLightMode ? "text-black" : "text-white"
                          }`}
                          style={{
                            marginRight: 12,
                            fontWeight: 600,
                            fontSize: 16,
                            color: item?.status === "cancel" ? "red" : "",
                            textDecoration:
                              item?.status === "cancel"
                                ? "line-through"
                                : "auto",
                          }}
                        >
                          {item?.quantity ? `${item?.quantity}x` : "1x"}
                        </span>
                        <span
                          className={` order-item-product-name ${
                            isLightMode ? "text-black" : ""
                          }`}
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: item?.status === "cancel" ? "red" : "",
                            textDecoration:
                              item?.status === "cancel"
                                ? "line-through"
                                : "auto",
                          }}
                        >
                          {item?.product_name}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item?.status === "cancel" ? (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemoveCancel(item?.product_id);
                            }}
                          >
                            <img
                              width={40}
                              height={40}
                              style={{
                                minWidth: 40,
                              }}
                              src={trashIcon}
                              alt="TRASH"
                            />
                          </div>
                        ) : (
                          <ButtonChangeStatusItem
                            onClick={(e) => {
                              e?.stopPropagation();
                              updateOrderNextStatus(
                                `${item.id}`,
                                item.status === "sent" ? "cooking" : "ready",
                                item.type
                              );
                            }}
                            onServed={() => onServed(`${item.id}`, item.type)}
                            type={item.status}
                          />
                        )}
                      </div>
                    </div>
                    {item?.note && (
                      <div>
                        <span
                          className={`order-item-product-note ${
                            isLightMode ? "note-light-mode" : ""
                          }`}
                        >
                          {item?.note}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </span>
      )}
    </Draggable>
  );
}

export default OrderV2;
